export default class
{
    constructor(selector = '.js-watch-navigation-status', activeClass = 'active') {
        this.activeClass = activeClass;
        this.ouuid = document.querySelector(selector).getAttribute('data-view-ouuid');
    }

    setActiveStatusToItemAndItemParent()
    {
        let self = this;

        if (!self.ouuid || self.ouuid.trim().length === 0)
            return ;

        let item = self._setStatusToItem();

        if(item) {
            self._setStatusToItemParent(item);
        }

        let $parentOuuid = $('[data-parent-menu-ouuid]');
        if ($parentOuuid.length) {
            $('[data-ouuid="' + $parentOuuid.data('parent-menu-ouuid') + '"]').addClass(self.activeClass);
        }
    }

    _setStatusToItem()
    {
        let self = this;

        let item = $('[data-ouuid="' + self.ouuid + '"]');
        // console.debug(item);

        if(item) {
            item.addClass(self.activeClass);
        }

        return item;
    }

    _setStatusToItemParent(item) {
        let self = this;

        let parent = item.parents('li.nav-item').children('a.nav-link');
        // console.debug(parent);

        if(parent) {
            parent.addClass(self.activeClass);
        }

        return parent
    }
}