const collapseSelectors = [
    '.target-collapse',
];
const collapseWrapperPrototype = '<div class="collapse" id="collapsible-ID_NUM" aria-labelledby="collapse-toggler-ID_NUM"></div>';

export default function initCollapse() {
    function initCollapseToggler($toggler, index) {
        var $anchor = $('<a/>');
        $anchor.attr({
            'data-toggle': "collapse",
            'data-target': "#collapsible-"+index,
            'aria-expanded': false,
            'aria-controls': "#collapsible-"+index,
            'role': "button",
            'href': "#",
            'id': "collapse-toggler-"+index
        });
        $toggler.replaceWith($anchor);
        $anchor.append($toggler);
    }
    function prepareWrapperPrototype(index) {
        let preparedPrototype = collapseWrapperPrototype.replace(/ID_NUM/g, index);
        return $(preparedPrototype);
    }

    // allows each collapse element to have a unique ID
    let masterIndex = 0;

    // cycle through all possible collapse selectors
    collapseSelectors.forEach(function(selector) {
        // find elements in DOM
        let $collapsables = $(selector);

        // process each of them
        $collapsables.each(function() {
            // prepare wrapper with index
            let $wrapper = $(prepareWrapperPrototype(masterIndex));

            // cycle through children to find first element (toggler) and rest is wrapped inside collapse
            $(this).children().each(function(childIndex, element) {
                if (childIndex == 0) {
                    initCollapseToggler($(this), masterIndex);
                } else {
                    $wrapper.append($(this));
                }
            });

            $(this).append($wrapper);
            $(this).collapse('hide');
            masterIndex++;
        });
    });
}
