//const highchartsTabsPrototype = "<div class=\"highcharts-table-tabs\"><nav><div class=\"nav nav-tabs\" id=\"highcharts-tab\" role=\"tablist\"><a class=\"nav-item nav-link active\" id=\"highcharts-chart-tab_INDEX\" data-toggle=\"tab\" href=\"#highcharts-chart_INDEX\" role=\"tab\" aria-controls=\"highcharts-chart_INDEX\" aria-selected=\"true\">SEE_CHART</a><a class=\"nav-item nav-link\" id=\"highcharts-table-tab_INDEX\" data-toggle=\"tab\" href=\"#highcharts-table_INDEX\" role=\"tab\" aria-controls=\"highcharts-table_INDEX\" aria-selected=\"false\">SEE_TABLE</a></div></nav><div class=\"tab-content\" id=\"nav-tabContent\"><div class=\"tab-pane fade show active highcharts-chart-tab\" id=\"highcharts-chart_INDEX\" role=\"tabpanel\" aria-labelledby=\"highcharts-chart-tab_INDEX\"></div><div class=\"tab-pane fade highcharts-table-tab\" id=\"highcharts-table_INDEX\" role=\"tabpanel\" aria-labelledby=\"highcharts-table-tab_INDEX\"><div class=\"table-responsive\"></div></div></div></div>";
const highchartsTabsPrototype = "" +
    "<div class=\"highcharts-table-tabs\">" +
        "<nav>" +
            "<div class=\"nav nav-tabs\" id=\"highcharts-tab\" role=\"tablist\">" +
                "<a class=\"nav-item nav-link active\" id=\"highcharts-chart-tab_INDEX\" data-toggle=\"tab\" href=\"#highcharts-chart_INDEX\" role=\"tab\" aria-controls=\"highcharts-chart_INDEX\" aria-selected=\"true\">SEE_CHART</a>" +
                "<a class=\"nav-item nav-link\" id=\"highcharts-table-tab_INDEX\" data-toggle=\"tab\" href=\"#highcharts-table_INDEX\" role=\"tab\" aria-controls=\"highcharts-table_INDEX\" aria-selected=\"false\">SEE_TABLE</a>" +
            "</div>" +
        "</nav>" +
        "<div class=\"tab-content\" id=\"nav-tabContent\">" +
            "<div class=\"tab-pane fade show active highcharts-chart-tab\" id=\"highcharts-chart_INDEX\" role=\"tabpanel\" aria-labelledby=\"highcharts-chart-tab_INDEX\"></div>" +
            "<div class=\"tab-pane fade highcharts-table-tab\" id=\"highcharts-table_INDEX\" role=\"tabpanel\" aria-labelledby=\"highcharts-table-tab_INDEX\">" +
                "<div class=\"table-responsive\"></div>" +
            "</div>" +
        "</div>" +
    "</div>";

const highChartsTranslations = getTranslationsForHighcharts();

const ibptColors = ["#34a9e1", "#2e2f7f", "#C73432", "#D06516","#EAB80F","#A1C049","#53AAA7","#834B93","#169B6F","#CD328E"];

function prepareHighchartsTabsPrototype(index) {
    let preparedPrototype = highchartsTabsPrototype.replace('SEE_CHART', highChartsTranslations.graph);
    preparedPrototype = preparedPrototype.replace('SEE_TABLE', highChartsTranslations.table);
    preparedPrototype = preparedPrototype.replace(/INDEX/g, index);

    return preparedPrototype;
}
function getTranslationsForHighcharts() {
    const body = document.querySelector('body');
    let json = body.getAttribute('data-translations');

    return JSON.parse(json);
}

function getChartType(tableElement) {
    var classList = tableElement.className.split(' ');
    // console.log(classList);

    if (classList.indexOf("highcharts-bar") > -1) {
        return "bar";
    } else if (classList.indexOf("highcharts-column") > -1) {
        return "column";
    } else if (classList.indexOf("highcharts-lettres-prior") > -1) {
        return "lettres-prior";
    } else if (classList.indexOf("highcharts-recommandes") > -1) {
        return "recommandes";
    } else if (classList.indexOf("highcharts-colis-prior") > -1) {
        return "colis-prior";
    } else if (classList.indexOf("highcharts-evolution-express-colis") > -1) {
        return "evolution-express-colis";
    } else if (classList.indexOf("highcharts-emploi") > -1) {
        return "emploi";
    } else if (classList.indexOf("highcharts-pie-data") > -1) {
        return "pie-data";
    }

    return 'pie';
}

function getPlotOptions(chartType) {
    if (chartType == 'pie') {
        return {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                    formatter: function() {
                        return '<b>'+ this.point.name +'</b><br/>'+
                            (''+this.point.y).replace('.', ',') + ' ' + highChartsTranslations.unit;
                    },
                    style: {
                        fontSize: '14',
                    }
                },
                showInLegend: true,
            }
        };
    }

    if (chartType == 'column') {
        return {
            column: {
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: 'none'
                }
            }
        };
    }
    
    if (chartType == 'evolution-express-colis') {
        return {
            label: {
                enabled: false
            },
        };
    }

    return {};
}

function getXAxis(chartType, table) {
    if (chartType == 'pie' || chartType == 'bar') {
        return {
            allowDecimals: false,
            title: {
                text: ''
            }
        };
    } else if (chartType == 'column') {
        let categories = [];

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index > 0) {
                categories.push(tr.children[0].innerText);
            }
        });

        return {
            allowDecimals: false,
            title: {
                text: highChartsTranslations.xAxis_years
            },
            categories: categories
        }        
    } else {
        let categories = [];

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index > 0) {
                categories.push(tr.children[0].innerText);
            }
        });

        return {
            categories: categories
        };
    }
}

function getYAxis(chartType, table) {
    if (chartType == 'lettres-prior' || chartType == 'recommandes' || chartType == 'colis-prior') {
        return [{
            index: 0,
            min: 80,
            max: 100,
            title: {
                text: '%'
            },
        }];
    } else if (chartType == 'evolution-express-colis') {
        return [{
            index: 0,
            title: {
                text: highChartsTranslations.evolutionExpressColis_xAxis_0
            },
        },
        {
            index: 1,
            opposite: true,
            title: {
                text: highChartsTranslations.evolutionExpressColis_xAxis_1
            },
        }];
    } else if (chartType == 'bar' || chartType == 'column') {
        var name = '';
        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index == 0) { // first line = titles
                if (tr.children.length <= 2) { // only one column = easy guess
                    name = tr.children[1].innerText;
                } else {
                    var tableId = table.getAttribute('id');
                    if (tableId == 'highcharts-table-23' || tableId == 'highcharts-table-24' || tableId == 'highcharts-table-25') {
                        name = "EUR";
                    } else if (tableId == 'highcharts-table-22') {
                        name = highChartsTranslations.highcharts_table_22_yAxis;
                    }
                }
            }
        });

        return [{
            index: 0,
            title: {
                text: name
            },
        }];
    } else if (chartType == 'emploi') {
        return [{
            index: 0,
            title: {
                text: highChartsTranslations.highcharts_table_22_yAxis
            },
        }];
    }

    return {};
}

function prepareChartHTML(table, index, parser, chartTitle) {
    const parent = table.parentNode;
    const doc = parser.parseFromString("<div id=\"highcharts-div-"+index+"\" class=\"highcharts-div\" data-index=\""+index+"\">&nbsp;</div>\n", "text/html");

    table.setAttribute('id', 'highcharts-table-'+index);
    table.insertAdjacentHTML('beforebegin', prepareHighchartsTabsPrototype(index));
    //table.insertAdjacentHTML('afterbegin', '<caption class="text-center">'+ chartTitle +'</caption>');

    const tabs = parent.getElementsByClassName('highcharts-table-tabs')[index];

    tabs.getElementsByClassName('highcharts-chart-tab')[0].appendChild(doc.getElementsByClassName('highcharts-div')[0]);
    tabs.getElementsByClassName('highcharts-chart-tab')[0].insertAdjacentHTML('beforeend', '<p class="p-4 mb-0 text-center"><small>'+ highChartsTranslations.disclaimer +'</small></p>');
    tabs.querySelectorAll('.table-responsive')[0].appendChild(table);
}

function fixTableValuesFormat(table) {
    Array.prototype.slice.call(table.querySelectorAll('td')).forEach(function(el) {
        el.innerText = el.innerText.replace('.', ',');
    });
}

function cleanTable(chartType, table) {
    if (chartType == 'pie-data') {
        let index = -1;

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr) {
            if (index == -1) {
                for (let i = 0; i < tr.children.length; i++) {
                    let child = tr.children[i];
                    if (child.className.split(' ').includes('highcharts-data-column')) {
                        index = i;
                        break;
                    }
                }
            }
            
            if (index >= 0) {
                tr.removeChild(tr.children[index]);
            }
        });
    }
}

function getSeries(chartType, table) {
    if (chartType == 'lettres-prior') {
        let data1 = [];
        let data2 = [];
        let data3 = [];

        let serie1 = {
            type: 'column'
        };
        let serie2 = { // line
            type: 'spline'
        };
        let serie3 = { // line
            type: 'spline'
        };

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index == 0) { // first line = titles
                serie1.name = tr.children[1].innerText;
                serie2.name = tr.children[2].innerText;
                serie3.name = tr.children[3].innerText;
            } else {
                data1.push(parseFloat(tr.children[1].innerText));
                data2.push(parseFloat(tr.children[2].innerText));
                data3.push(parseFloat(tr.children[3].innerText));
            }
        });

        serie1.data = data1;
        serie2.data = data2;
        serie3.data = data3;

        // console.log([serie1, serie2, serie3]);

        return [serie1, serie2, serie3];
    } else if (chartType == 'recommandes' || chartType == 'colis-prior' || chartType == 'evolution-express-colis') {
        let data1 = [];
        let data2 = [];

        let serie1 = {
            type: 'column',
            dataLabels: {
                enabled: true,
            }
        };
        let serie2 = { // line
            type: 'spline',
            dataLabels: {
                enabled: true,
            },
            label: {
                enabled: false
            },
        };

        if (chartType == 'evolution-express-colis') {
            serie1.yAxis = 0;
            serie2.yAxis = 1;
        }

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index == 0) { // first line = titles
                serie1.name = tr.children[1].innerText;
                serie2.name = tr.children[2].innerText;
            } else {
                data1.push(parseFloat(tr.children[1].innerText));
                data2.push(parseFloat(tr.children[2].innerText));
            }
        });

        serie1.data = data1;
        serie2.data = data2;

        // console.log([serie1, serie2]);

        return [serie1, serie2];
    } else if (chartType == 'emploi') {
        let data1 = [];
        let data2 = [];

        let serie1 = {
            type: 'column',
            dataLabels: {
                enabled: true,
            }
        };
        let serie2 = {
            type: 'column',
            dataLabels: {
                enabled: true,
            }
        };

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index == 0) { // first line = titles
                serie1.name = tr.children[1].innerText;
                serie2.name = tr.children[2].innerText;
            } else {
                data1.push(parseFloat(tr.children[1].innerText));
                data2.push(parseFloat(tr.children[2].innerText));
            }
        });

        serie1.data = data1;
        serie2.data = data2;

        // console.log([serie1, serie2]);

        return [serie1, serie2];
    } else if (chartType == 'pie-data') {
        let data1 = [];

        let serie1 = {
            type: 'pie',
            dataLabels: {
                enabled: true
            },
            showInLegend: true,
            legend: {
                align: "right",
                layout: "horizontal",
                verticalAlign: "middle"
            },
        };

        Array.prototype.slice.call(table.querySelectorAll('tr')).forEach(function(tr, index) {
            if (index == 0) { // first line = titles
                serie1.name = tr.children[2].innerText;
            } else {
                let row = {
                    name: tr.children[0].innerText,
                    round: tr.children[2].innerText,
                    y: parseFloat(tr.children[1].innerText),
                    dataLabels: {
                        enabled: true,
                        format: tr.children[2].innerText
                    }
                };
                data1.push(row);
            }
        });

        serie1.data = data1;

        return [serie1];
    }
}

function initChart(chartType, index, table, chartTitle) {
    const Highcharts = require('highcharts');
    require('highcharts/modules/data')(Highcharts);
    require('highcharts/modules/series-label')(Highcharts);
    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/modules/accessibility')(Highcharts);
    Highcharts.SVGRenderer.prototype.symbols.download = function (x, y, w, h) {
        var path = [
            // Arrow stem
            'M', x + w * 0.5, y,
            'L', x + w * 0.5, y + h * 0.7,
            // Arrow head
            'M', x + w * 0.3, y + h * 0.5,
            'L', x + w * 0.5, y + h * 0.7,
            'L', x + w * 0.7, y + h * 0.5,
            // Box
            'M', x, y + h * 0.9,
            'L', x, y + h,
            'L', x + w, y + h,
            'L', x + w, y + h * 0.9
        ];
        return path;
    };
    Highcharts.setOptions({
        lang: {
            accessibility: {
                exporting: {
                    chartMenuLabel: highChartsTranslations.chartMenuLabel,
                    menuButtonLabel: highChartsTranslations.menuButtonLabel,
                    exportRegionLabel: highChartsTranslations.exportRegionLabel
                }
            }
        }
    });

    const $table = $(table);
    let highchartsOptions = undefined;

    if (chartType == 'pie') {
        highchartsOptions = {
            data: {
                table: table,
                switchRowsAndColumns: false
            },
            chart: {
                type: chartType
            },
            plotOptions: getPlotOptions(chartType),
            xAxis: getXAxis(chartType, table),
            title: {
                text: chartTitle
            },
            legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "middle"
            },
            tooltip: {enabled: true},
            credits: {enabled: true, href: "http://www.ibpt.be", text: "www.ibpt.be"}
        };
    } else if (chartType == 'bar' || chartType == 'column') {
        highchartsOptions = {
            data: {
                table: table,
                switchRowsAndColumns: false
            },
            chart: {
                type: chartType,
                marginTop: 48
            },
            plotOptions: getPlotOptions(chartType),
            xAxis: getXAxis(chartType, table),
            yAxis: getYAxis(chartType, table),
            title: {
                text: ""
            },
            //colors: ibptColors,
            tooltip: {enabled: true},
            credits: {enabled: true, href: "http://www.ibpt.be", text: "www.ibpt.be"}
        };
    } else if (chartType == 'lettres-prior' || chartType == 'recommandes' || chartType == 'colis-prior' || chartType == 'evolution-express-colis') {
        const series = getSeries(chartType, table);
        
        highchartsOptions = {
            series: series,
            plotOptions: getPlotOptions(chartType),
            xAxis: getXAxis(chartType, table),
            yAxis: getYAxis(chartType, table),
            chart: {
                marginTop: 48
            },
            title: {
                text: ""
            },
            // legend: {
            //     align: "right",
            //     layout: "vertical",
            //     verticalAlign: "middle"
            // },
            tooltip: {enabled: true},
            credits: {enabled: true, href: "http://www.ibpt.be", text: "www.ibpt.be"}
        };
    } else if (chartType == 'emploi') {
        const series = getSeries(chartType, table);
        
        highchartsOptions = {
            series: series,
            plotOptions: getPlotOptions(chartType),
            xAxis: getXAxis(chartType, table),
            yAxis: getYAxis(chartType, table),
            chart: {
                marginTop: 48
            },
            title: {
                text: ""
            },
            tooltip: {enabled: true},
            credits: {enabled: true, href: "http://www.ibpt.be", text: "www.ibpt.be"}
        };
    } else if (chartType == 'pie-data') {
        const series = getSeries(chartType, table);

        highchartsOptions = {
            series: series,
            plotOptions: getPlotOptions(chartType),
            xAxis: getXAxis(chartType, table),
            title: {
                text: ""
            },
            legend: {
                align: "right",
                layout: "vertical",
                verticalAlign: "middle"
            },
            tooltip: {
                pointFormat: "{point.round}"
            },
            credits: {enabled: true, href: "http://www.ibpt.be", text: "www.ibpt.be"}
        };
    }

    highchartsOptions.colors = ibptColors;

    highchartsOptions.exporting = {
        buttons: {
            contextButton: {
                symbol: 'download',
                theme: {
                    fill: '#e5e3e0',
                    padding: 8
                },
                menuItems: [
                    'downloadPNG',
                    'downloadPDF',
                ]
            }
        }
    };

    highchartsOptions.responsive = {
        rules: [{
            condition: {
                maxWidth: 576
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                }
            }
        }]
    }

    // console.log(highchartsOptions);

    Highcharts.chart('highcharts-div-'+index, highchartsOptions);
}

function getChartTitle(highchart) {
    var $highchart = $(highchart);
    var $title = $highchart.prev();
    
    if ($title.is('h2') || isNaN(parseInt($title.text()))) {
        return $title.text();
    } else {
        var $currElement = $title;
        while(!$currElement.is('h2')) {
            $currElement = $currElement.prev();
        }

        return $currElement.text() + ' - ' + $title.text();
    }
}

export default function highcharts() {
    const highcharts = document.getElementsByClassName('highcharts');
    const parser = new DOMParser();

    for(let i = 0; i < highcharts.length; i++)
    {
        const chartType = getChartType(highcharts[i]) || 'pie';

        const highchartTitle = getChartTitle(highcharts[i]);

        // insert the tab HTML
        prepareChartHTML(highcharts[i], i, parser, highchartTitle);

        // initialize highcharts
        initChart(chartType, i, highcharts[i], highchartTitle);

        cleanTable(chartType, highcharts[i]); // remove data columns
        fixTableValuesFormat(highcharts[i]); // e.g.: 3.14 -> 3,14
    }
}
