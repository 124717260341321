export default function cookieBanner($backToTop) {
  const $cookieBox = $("#euCookie"),
      $closeCookie = $cookieBox.find('#closeCookie'),
      url = window.location.pathname.substr(window.location.pathname.lastIndexOf('/') + 1);

  if(localStorage.getItem('cookieAlert') == 'true'){
    $cookieBox.hide();
  };

  if (localStorage.getItem('cookieAlert') === null && url != 'cookies') {
    $cookieBox.show();

    let b2TnewBottom = $cookieBox.outerHeight() + parseInt($backToTop.css('bottom'));
    $backToTop.css('bottom', b2TnewBottom);
    
    $closeCookie.bind("click", function(e) {
      e.preventDefault();
      localStorage.setItem("cookieAlert","true");
      $cookieBox.slideUp();
      $backToTop.removeAttr('style');
    });
  };
};