export default function closeAlert() {
  if(!sessionStorage.getItem("alert")) {
    $('.alert').show();
  } else {
    $('.alert').each(function() {
      const hiddenAlerts = JSON.parse(sessionStorage.getItem("alert"));

      if($.inArray( $(this).attr('id'), hiddenAlerts.ids) === -1) {
        $(this).show();
      }
    });
  }
  $('.alert').on('closed.bs.alert', function () {
    const alertId = $(this).attr('id');
    let alerts = {'ids':[]};

    if(sessionStorage.getItem("alert")) {
      alerts = JSON.parse(sessionStorage.getItem("alert"));
    }
    alerts.ids.push(alertId)
    sessionStorage.setItem("alert", JSON.stringify(alerts))
  })
}