// const $ = require('jquery');
window.jQuery = require('jquery');
window.$ = window.jQuery;
const $currentLang = $('html').attr('lang');
const $backToTop = $('#back2top');

// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap');
require('holderjs');

import highcharts from './highcharts';
// highcharts
highcharts();


//import DataTable from './datatables/media/js';

require('../css/app.scss');

if ($currentLang.length == 0) {
  $currentLang == "en";
}

require('./locales/'+$currentLang+'-BE.min.js');
require('./ab-datepicker.smals.js');


const enquire = require('enquire.js');
// const slick = require('slick-carousel');

import adminMenu from '@elasticms/admin-menu';
import initCollapse from './content-collapse';
import cookieBanner from './cookie-banner';
import AOS from 'aos';
import Navigation from './Navigation';
import closeAlert from './alert';

//require( 'datatables.net-dt' );
import dt from 'datatables.net';
require ('./dataTables.bootstrap4.min.js');
const dTLanguage = require('./dataTables-i18n/'+$currentLang+'.json');
const translations = JSON.parse(document.body.getAttribute('data-translations'));

let navigation = new Navigation();
navigation.setActiveStatusToItemAndItemParent();

$(function() {
  adminMenu('esm_demo_admin', '<span>' + (translations.back_to_ems === undefined ? 'Back to ems' : translations.back_to_ems) + '</span>')
  global.init();
  initCollapse();
  cookieBanner($backToTop);
  closeAlert();
});

const global = {
  init: function() {
    navbar.init();
    // if ($totalTocItems > 1 && $('#toc').length) {
    //   toc.init();
    // }
    daterange.init();
    // smoothScroll.init();
    back2Top.init();
    search.init();
    searchFilters.init();
    stickyHeader.init();
    animations.init();
    pageHeight.init();
    datatables.init();
  }
};
/* ---------------------------------------------- Sticky header */
const $siteHeader = $('.site-header');
const headerMb = $siteHeader.css('margin-bottom');
const $mainMenu = $('.main-menu');
const $stickyLogo = $('.sticky-logo');

const $navtoggler = $('.navbar-toggler');
const $navMobile = $('.site-header > .navbar');

const stickyHeader = {
  init: function() {
    //stickyHeader.stickyMobile();
    enquire.register("screen and (min-width: 992px)", {
        match : function() {
          if ($siteHeader.length) stickyHeader.stickyDesktop();
        },
        unmatch : function() {
          stickyHeader.cleanSticky();
          $(window).off('scroll');
        }
    });
  },
  stickyDesktop: function() {
    const stickyTop = $mainMenu[0].offsetTop;
    const stickyHeight = $mainMenu[0].offsetHeight;
    let stickyLogoOffset = utils.offset($stickyLogo[0]);
    let logoLeft = stickyLogoOffset.left;

    window.onresize = function() {
      stickyLogoOffset = utils.offset($stickyLogo[0]);
      logoLeft = stickyLogoOffset.left;
    }
    $(window).on('scroll',function() {
      if (window.pageYOffset >= (stickyTop - 4)) {
        $mainMenu.addClass('sticky-nav');
        $siteHeader.css('margin-bottom',(stickyHeight + parseInt(headerMb)));

        if (logoLeft < 2) {
          $stickyLogo.css({'margin-left': -50 - logoLeft});
        }
      } else {
        stickyHeader.cleanSticky();
      }
    });
  },
  cleanSticky: function() {
    $mainMenu.removeClass('sticky-nav');
    $siteHeader.removeAttr('style');
    $stickyLogo.removeAttr('style');
  },
  stickyMobile: function(){
    $navtoggler.on('click',function() {
      if ($navtoggler.attr('aria-expanded') == "false") {
        $navMobile.css('position','relative');
      } else {
        $navMobile.removeAttr('style');
      }
    });
  }
}
/* ---------------------------------------------- Navbar */
const navbar = {
  init: function() {
    this.autoclose();
    this.searchFocus();
    //this.multipleDropdown();
    //this.selectMenu();
  },
  autoclose: function() {
    // hide collapse nav when click outside
    $(document).click(function (event) {
      //navbar.hideShown();
      let clickover = $(event.target).parents();
      if(!clickover.hasClass("aside-xs") && !clickover.hasClass("search-form")) {
        navbar.hideShown();
      }
      enquire.register("screen and (max-width: 991.98px)", {
          match : function() {
            if(!clickover.hasClass("navbar-collapse")) {
              $('.navbar-collapse.collapse.show').collapse('hide');
            }
          }
      });
    });
    // hide other collapse when one is shown
    $('[data-group="mobile-nav"]').on('click',function() {
      navbar.hideShown();
    });
  },
  hideShown: function() {
    //$('.navbar-nav .collapse.show').collapse('hide');
    $('.navbar-nav .collapse.show').collapse('hide');
  },
  searchFocus: function() {
    $('#menu-search, #menu-mobile-search').on('shown.bs.collapse', function() {
      $(this).find('input').focus();
    });
  }
};
/* ------------------------------------ Smooth Scrolling */
const back2Top = {
  init: function() {
    // Back to top / appear on scroll
    if ($backToTop.length) {
        $backToTop.hide();
        $(window).on('scroll', function() {
            if ($(this).scrollTop() > 100) {
                $backToTop.fadeIn();
            } else {
                $backToTop.fadeOut();
            }
        });
    }
  }
}
const smoothScroll = {
  init: function() {
    // smooth scroll if url have #
    if(window.location.hash) {
      //stop jump to hash straight away
      $(window).scrollTop(0);
      // stop jump to hash again a bit later
      setTimeout(function() {
        $(window).scrollTop(0);
      }, 1);
      setTimeout(function() {
        smoothScroll.scrollTo(window.location.hash);
      }, 10);
    }
  },
  scrollTo: function(target) {
    if( target.length ) {
      $("html, body").stop().animate( { scrollTop: $(target).offset().top }, 500, function() {
        window.location.hash = target;
      });
    }
  },
}
/* ------------------------------------------- DateRange */
const daterange = {
  init: function() {
    const calendarStart = $('#datepicker-calendar-date_start');
    const calendarEnd = $('#datepicker-calendar-date_end');
    $('#date_start').datepicker({
      firstDayOfWeek: 1,
      outputFormat: 'dd/MM/yyyy',
      theme: 'bootstrap',
      gainFocusOnConstruction: false,
      next: '#date_end' // The date in '#startdate' must be before or equal to the date in '#enddate'
    });
    $('#date_end').datepicker({
      firstDayOfWeek: 1,
      outputFormat: 'dd/MM/yyyy',
      theme: 'bootstrap',
      gainFocusOnConstruction: false,
      previous: '#date_start' // The date in '#enddate' must be after or equal to the date in '#startdate'
    });
  }
};

/* ---------------------------------------------- Search */
const $filtersActive = $('.filters-active');
const $filters = $('.filters');
const $filterCol = $('.search-results > .row > .col-lg-4');
const $mobileFilters = $('#mobile-filters');
const $mobileActive = $('.search-header');

const search = {
  init: function() {
    this.checkboxesMore();
    enquire.register("screen and (max-width: 991.98px)", {
        match : function() {
          search.filtersMobile();
        },
        unmatch : function() {
          search.filtersDesktop();
        }
    });
  },
  filtersMobile: function() {
    $filters.detach();
    $filtersActive.detach();
    $mobileFilters.append($filters);
    $mobileActive.append($filtersActive);
  },
  filtersDesktop: function() {
    $filterCol.append($filtersActive).append($filters);
  },
  checkboxesMore: function() {
    const $collapseLink = $filters.find('.form-group [data-toggle="collapse"]');
    $collapseLink.each(function() {
      const $this = $(this);
      const $textMore = $this.text();
      const $textLess = $this.attr('data-text');

      $this.on('click',function() {
        const $target = $this.attr('href');
        const $targetInput = $($target+' .custom-checkbox:first-child input');
        if ( $this.attr('aria-expanded') == 'false' ) {
          $this.text($textLess).attr('data-text',$textMore);
        } else {
          $this.text($textMore).attr('data-text',$textLess);
        }
        $($target).on('shown.bs.collapse', function () {
          $targetInput.focus();
        });
      });
    });
  }
}

const searchFilters = {
    init: function () {
        this.selectLegislature();
        this.autocompleteKeyword('ajax-member');
        this.autocompleteKeyword('ajax-keyword');
    },
    selectLegislature: function () {
        let select = $("select#legislature");
        let start = $('input#date_start');
        let end = $('input#date_end');

        select.change(function() {
            start.val($(this).find(':selected').data("date-start"));
            end.val($(this).find(':selected').data("date-end"));
        });

        if (start.val() && end.val()) {
            select.find('option').each(function () {
                if ($(this).data('date-start') === start.val() && $(this).data('date-end') === end.val()) {
                    $(this).attr('selected', true);
                }
            });
        }
    },
    autocompleteKeyword: function (id) {
        let input = document.getElementById(id);

        if (typeof(input) == 'undefined' || input == null) {
            return;
        }

        let awesome = new Awesomplete(input, {
            replace: function(suggestion) {
              let filter = $(this.input).data('filter');
              $('input[name='+filter+']').val(suggestion.value);
              this.input.value = suggestion.label;
            }
        });

        $('input#'+id).on("input", function() {
            $.ajax({
                'method': 'GET',
                'url': input.dataset.url,
                'data': {'q': this.value},
                'dataType': 'json'
            }).done(function (data) {
                awesome.list=data;
            });
        });
    }
};
/* ------------------------------------- Animations */
const animations = {
  init: function() {
    AOS.init({
      duration: 1000,
      easing: 'easeInOutQuart'
    });
  }
};

/* ------------------------------------- Page height */
const pageHeight = {
  init: function() {
    var $page = $('.page');

    if ($page.length) {
      $page.css('minHeight', 'calc(100vh - '+ $('.site-footer').outerHeight(true) +'px - '+$('.site-header').outerHeight(true) +'px)');
    }

    // $(window).on('resize', function() {
    //   pageHeight.init();
    // });
  }
};
/* ------------------------------------- Data tables */
const datatables = {
  init: function() {
    $('#radio-frequencies').DataTable({
      "lengthMenu": [ [50, 100, 500, -1], [50, 100, 500, dTLanguage.all]],
      "info":     false,
      "language": dTLanguage
    });
    $('.file-meta-table').DataTable({
      "info":     false,
      "paging":   false,
      "language": dTLanguage,
      "order": [[ 0, 'desc' ]]
    });
  }
};

/* ------------------------------------- Misc. functions */
const utils = {
  offset: function(el) {
    // element offset position relative to window
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }
};

$('.page-sidebar .menu .list-item.active').each(function() {
  var $parent = $(this).parents('.collapse');
  var $collapseAnchor = $parent.prev();

  $collapseAnchor.attr('aria-expanded', true);
});
